var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-new-city-logs-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看新一线日志",
            visible: _vm.dialogVisible,
            width: "560px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
            },
            [
              _vm.logs.length > 0
                ? _c(
                    "el-timeline",
                    _vm._l(_vm.logs, function (log) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: log.id,
                          attrs: { timestamp: _vm.contentText(log) },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                log.creator.realName +
                                  "@" +
                                  log.creator.nickname
                              ) +
                              " \n                    "
                          ),
                          _c("span", { staticClass: "time" }, [
                            _vm._v(_vm._s(log.created)),
                          ]),
                        ]
                      )
                    }),
                    1
                  )
                : _c("div", { staticClass: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@src/assets/images/pic_empty.png"),
                      },
                    }),
                    _c("p", [_vm._v("暂无日志记录")]),
                  ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="ko-before">
        <!-- <div :class="['candidate-resume', 'col-16', 'row', 'row-overflow', 'candidate-resume-width']"> -->
        <div :class="['candidate-resume']">
            <div>
                <candidate-resume-detail 
                    v-show="activeValue == 1"
                    ref="candidateResumeDetail" 
                    @candidate-detail-refresh='candidatedetailrefresh' 
                    class="card" 
                    :canEdit="canEdit" 
                    :candidate-id="candidateId" 
                    :is-firm="isFirm" 
                    :resume-id="resumeId"
                ></candidate-resume-detail>
                <comments
                    v-show="activeValue == 4 && ((canEdit && isFirm) || isCloudRecommendation)"
                    ref="comments"
                    :isEmail="true"
                    :team="true"
                    :prefetch="false"
                    :postId="candidateId"
                    commentType="candidate"
                    :commentObjectInfo="candidateDetailData"
                    placeholderText="关于候选人的互动交流"
                    :shareData="shareData"
                    class="card candidate-resume-comments">
                </comments>
            </div>
        </div>
    </div>
</template>

<script>
    import Comments from '#/component/comments/index';
    import CandidateResumeDetail from './candidateResumeDetail.vue';

    export default {
        components: {
            Comments,
            CandidateResumeDetail
        },
        mixins: [
        ],
        props: {
            canEdit: {
                type: Boolean,
                default: true
            },
            candidateId:String,
            isFirm:Boolean,
            resumeId:String,
            isCloudRecommendation: false,
            shareData: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            candidateDetailData: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            activeValue: Number
        },
        data() {
            return {
            };
        },
        mounted () {
            this.comments = this.$refs.comments;
            this.candidateResumeDetail = this.$refs.candidateResumeDetail;
        },
        methods: {
            updateArticle(vm, originVm){
                this.candidateResumeDetail.update(vm, originVm);
            },

            refresh(){
                this.comments.refresh();
            },
            candidatedetailrefresh(){
                this.$emit('candidate-detail-refresh')
            }
        }
    }
</script>

<style lang="scss">

.candidate-resume{
    // width: 830px;
    padding: 0;
    margin: 0;

    .article-content{
        .show-more{
            display: none;

            &.active{
                display: block;
            }
        }
    }

    .article-form-wrapper{
        .ko-form{
            display: none;

            &.active{
                display: block;
            }
        }
    }

    .attachment-item{
        .secret{
            display: none;
        }
    }

    .candidate-article {
        position: relative;
    }

    .highlight-match-tip {
        position: absolute;
        top: 45px;
        right: 0;
        font-size: 12px;
        color: #fff;
        background-color: #87E9F1;
        padding: 10px 22px 10px 20px;
        line-height: 1.71;
        background: linear-gradient(#87E9F1, #85E7F2, #75D5F8);
        font-family: microsoftyahei;
        display: table;
        z-index: 2;

         p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }

        .close {
            color: #fff;
            text-shadow: none;
            opacity: 1;
            margin-top: 0;
            position: absolute;
            display: block;
            z-index: 2;
            right: 0;
            top: -3px;
            font-size: 14px;
            padding: 4px;

            .icon-close {
                font-size: 12px;
                color: #fff;
            }
        }

        .icon-bulb {
            width: 30px;
            height: 32px;
            background-size: cover;
            vertical-align: text-bottom;
            display: inline-block;
            background-image: url('~@src/assets/images/icon/bulb.png');
        }

        > div {
            display: table-cell;
            vertical-align: middle;
            text-indent: 0;
        }

        > .left {
            padding-right: 10px;
        }
    } 

    slot {
        position: relative;
        display: block;
    }
}

</style>

<template>
    <div class="edit-new-city-logs-dialog">
        <el-dialog
            title="查看新一线日志"
            :visible.sync="dialogVisible"
            width="560px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
            <div class="content" v-loading="loading">
                <el-timeline v-if="logs.length > 0">
                    <el-timeline-item
                        v-for="log in logs"
                        :key="log.id"
                        :timestamp="contentText(log)">
                        {{log.creator.realName + '@' + log.creator.nickname}} 
                        <span class="time">{{log.created}}</span>
                    </el-timeline-item>
                </el-timeline>
                <div v-else class="empty">
                    <img src="~@src/assets/images/pic_empty.png" />
                    <p>暂无日志记录</p>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import newFirstCityData from '#/js/config/newFirstCityData.json';

export default {
    props: {
        tbdResumeId: String,
    },
    data() {
        return {
            newFirstCityData: newFirstCityData,

            loading: false,
            dialogVisible: false,
            logs: [],
        };
    },

    mounted() {
        
    },
    computed: {
        
    },
    methods: {
        show() {
            this.dialogVisible = true;
            
            let parmas = {
                resumeId: this.tbdResumeId
            }
            this.loading = true;
            _request({
                url: '/openapi/Candidate/GetNewCityExpectationsHistory',
                method: 'POST',
                data: parmas,
                baseURL: "LbdOpenApi",
            }).then(res => {
                this.logs = res;
                this.loading = false;
            }).catch( err => {
                console.log(err);
                this.loading = false;
            });
        },
        handleClose() {
            this.dialogVisible = false;
        },

        contentText(log) {
            // console.log(log);
            let locations = [];
            this.newFirstCityData.forEach(item => {
                log.locations.forEach(el => {
                    if(el == item.value) {
                        locations.push(item.label);
                    }
                })
            })
            let locationsText = locations.join('、');
            // console.log(locationsText);

            let expectationType = log.expectationType;
            let address = log.address.replace(/\s/g, '、');
            if( expectationType == '1') {
                return '新一线机会：不考虑';
            } else {
                if(expectationType == 3) {
                    return '新一线机会：现在考虑' + locationsText + `${address ? '及'+address : ''}`;
                } else if(expectationType == 5) {
                    return '新一线机会：半年内考虑' + locationsText + `${address ? '及'+address : ''}`;
                } else if(expectationType == 7) {
                    return '新一线机会：1年内考虑' + locationsText + `${address ? '及'+address : ''}`;
                } else if(expectationType == 9) {
                    return '新一线机会：1-3年考虑' + locationsText + `${address ? '及'+address : ''}`;
                } else if(expectationType == 11) {
                    return '新一线机会：3年以上考虑' + locationsText + `${address ? '及'+address : ''}`;
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.edit-new-city-logs-dialog {
    /deep/.el-dialog__body {
        padding: 20px 20px 0;
        overflow-y: auto;
        .content {
            max-height: 428px;
            .el-timeline-item__tail {
                border-color: #38BC9C;
            }
            .el-timeline-item__node {
                background-color: #38BC9C;
            }
            .el-timeline-item__wrapper {
                .time {
                    color: #9B9B9B;
                }
                .el-timeline-item__timestamp {
                    color: #666;
                    line-height: 20px;
                }
            }

            .empty {
                padding-bottom: 20px;
                text-align: center;
            }
        }
    }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-first-city-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新一线机会",
            visible: _vm.dialogVisible,
            width: "560px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
            },
            [
              _c("div", { staticClass: "top-tip" }, [
                _vm._v(
                  "人选是否考虑新一线城市的工作机会？（如：长沙，武汉，成都等）"
                ),
              ]),
              _c(
                "div",
                { staticClass: "thinking-city" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "是否及多久考虑" },
                      on: { change: _vm.changeThinkingTime },
                      model: {
                        value: _vm.thinkingTime,
                        callback: function ($$v) {
                          _vm.thinkingTime = $$v
                        },
                        expression: "thinkingTime",
                      },
                    },
                    _vm._l(_vm.timeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCitySelectShow,
                          expression: "isCitySelectShow",
                        },
                      ],
                    },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("考虑城市")]),
                      _c(
                        "ul",
                        { staticClass: "city-list" },
                        _vm._l(_vm.cityList, function (city) {
                          return _c(
                            "li",
                            {
                              key: city.value,
                              class: {
                                selected: _vm.checkCityIfSelected(city.value),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectNewFirstCity(city)
                                },
                              },
                            },
                            [_vm._v(_vm._s(city.label))]
                          )
                        }),
                        0
                      ),
                      _c("el-input", {
                        staticClass: "other-city-input",
                        attrs: {
                          placeholder:
                            "其他城市（多个城市以空格隔开，限25个汉字）",
                        },
                        on: { input: _vm.checkOtherCity },
                        model: {
                          value: _vm.otherCity,
                          callback: function ($$v) {
                            _vm.otherCity = $$v
                          },
                          expression: "otherCity",
                        },
                      }),
                      _c("div", { staticClass: "other-city-input-error" }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "prompt-message" }, [
                    _vm._v(
                      "提示：首次填写，选择了是否考虑就加积分，其他为选填项，请尽量详细填写"
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("还没问"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("确定已问")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
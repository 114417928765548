<template>
    <div class="new-first-city-dialog">
        <el-dialog
            title="新一线机会"
            :visible.sync="dialogVisible"
            width="560px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
            <div class="content" v-loading="loading">
                <div class="top-tip">人选是否考虑新一线城市的工作机会？（如：长沙，武汉，成都等）</div>
                <div class="thinking-city">
                    <el-select v-model="thinkingTime" placeholder="是否及多久考虑" @change="changeThinkingTime">
                        <el-option
                            v-for="item in timeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <div v-show="isCitySelectShow">
                        <div class="title">考虑城市</div>
                        <ul class="city-list">
                            <li :class="{'selected': checkCityIfSelected(city.value)}" v-for="city in cityList" :key="city.value" @click="selectNewFirstCity(city)">{{ city.label }}</li>
                        </ul>
                        <el-input class="other-city-input" v-model="otherCity" placeholder="其他城市（多个城市以空格隔开，限25个汉字）" @input="checkOtherCity"></el-input>
                        <div class="other-city-input-error"></div>
                    </div>
                    <p class="prompt-message">提示：首次填写，选择了是否考虑就加积分，其他为选填项，请尽量详细填写</p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">还没问</el-button>
                <el-button type="primary" @click="onSubmit" :loading="submitLoading">确定已问</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import newFirstCityData from '#/js/config/newFirstCityData.json';

export default {
    props: {
        tbdResumeId: String,
        candidateId: String,
        newCityExpectationsInfo: Object
    },
    data() {
        return {
            loading: false,
            submitLoading: false,
            dialogVisible: false,
            type: '',

            isCitySelectShow: true,

            thinkingTime: '',
            timeOptions: [
                {
                    value: '1',
                    label: '不考虑'
                },
                {
                    value: '3',
                    label: '现在考虑'
                },
                {
                    value: '5',
                    label: '半年内'
                },
                {
                    value: '7',
                    label: '1年内'
                },
                {
                    value: '9',
                    label: '1-3年'
                },
                {
                    value: '11',
                    label: '3年以上'
                },
            ],
            cityList: newFirstCityData,
            selectNewFirstCityList: [],
            otherCity: '',
        };
    },

    mounted() {
        
    },

    methods: {
        show(type) {
            this.type = type;
            this.dialogVisible = true;
            // 修改
            // console.log(this.newCityExpectationsInfo);
            if(type == 'update') {
                this.thinkingTime = this.newCityExpectationsInfo.expectationType.toString();
                this.otherCity = this.newCityExpectationsInfo.address;

                this.selectNewFirstCityList = [];
                this.cityList.forEach(item => {
                    this.newCityExpectationsInfo.locations.forEach(el => {
                        if(el == item.value) {
                            this.selectNewFirstCityList.push(item);
                        }
                    })
                })

                if(this.thinkingTime == '1') {
                    this.isCitySelectShow = false;
                }
            }
        },

        changeThinkingTime(val) {
            if(val == '1') {
                this.isCitySelectShow = false;
                
                this.selectNewFirstCityList = [];
                this.otherCity = '';

                let error = document.getElementsByClassName("other-city-input-error")[0];
                let input = document.getElementsByClassName("other-city-input")[0];
                error.innerHTML = "";
                input.classList.remove("input-error");
            } else {
                this.isCitySelectShow = true;
            }
        },

        checkCityIfSelected(value) {
            function isInArr(item) {
                return item.value == value;
            }
            return this.selectNewFirstCityList.some(isInArr);
        },
        selectNewFirstCity(item) {
            let itemIndex = -1;
            this.selectNewFirstCityList.forEach((city, index) => {
                if(city.value == item.value) {
                    itemIndex = index;
                }
            })
            if(itemIndex > -1) {
                this.selectNewFirstCityList.splice(itemIndex, 1);
            } else {
                this.selectNewFirstCityList.push(item);
            }
            console.log(this.selectNewFirstCityList);
        },

        handleClose() {
            this.dialogVisible = false;
        },
        // 其他城市输入验证
        checkOtherCity(val) {
            let error = document.getElementsByClassName("other-city-input-error")[0];
            let input = document.getElementsByClassName("other-city-input")[0];
            if(val) {
                if(!/^([\u4E00-\u9FA5\s])+$/.test(val)) {
                    error.innerHTML = "只能输入中文,多个城市以空格隔开";
                    input.classList.add("input-error");
                    return;
                } else if(val.replace(/\s/g,'').length > 25) {
                    error.innerHTML = "字数不超过25个字";
                    input.classList.add("input-error");
                    return;
                } else{
                    error.innerHTML = "";
                    input.classList.remove("input-error");
                }
            } else {
                error.innerHTML = "";
                input.classList.remove("input-error");
            }
        },
        onSubmit() {
            if(!this.thinkingTime) {
                shortTips('请填写人选是否及多久考虑');
                return;
            }
            // if(this.thinkingTime != '1') {
            //     if(this.selectNewFirstCityList.length == 0 && !this.otherCity ) {
            //         shortTips('请选择或填写考虑城市');
            //         return;
            //     }
            // } else { // 选择不考虑的时候
            //     this.selectNewFirstCityList = [];
            //     this.otherCity = '';
            // }

            if(document.getElementsByClassName("other-city-input-error")[0].innerHTML != "") {
                return;
            }

            let locations = this.selectNewFirstCityList.map(el => {
                return Number(el.value);
            })
            // 输入多个空格的时候，改为一个空格，再去除首尾空格
            let address = this.otherCity.replace(/\s+/g,' ').trim();
            let parmas = {
                resumeId: this.tbdResumeId,
                expectationType: this.thinkingTime,
                locations: locations,
                address: address
            };
            let url;
            if(this.type == 'update') { // 编辑
                url = '/openapi/Candidate/UpdateNewCityExpectations'
                delete parmas.resumeId;
                parmas.id = this.newCityExpectationsInfo.id;
            } else {
                url = '/openapi/Candidate/CreateNewCityExpectations'
            }
            // console.log(parmas);
            this.loading = true;
            this.submitLoading = true;
            _request({
                url: url,
                method: 'POST',
                data: parmas,
                baseURL: "LbdOpenApi",
            }).then(res => {
                this.loading = false;
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit('refresh-new-city-expectations', this.tbdResumeId);
            }).catch( err => {
                console.log(err);
                this.loading = false;
                this.submitLoading = false;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.new-first-city-dialog {
    /deep/.el-dialog__body {
        padding: 0;
        .content {
            margin-bottom: 20px;
            .top-tip {
                padding: 0 20px;
                height: 50px;
                line-height: 50px;
                background-color: #F8F9ED;
                color: #FA765D;
                font-size: 14px;
            }

            .thinking-city {
                padding: 0 54px;
                .el-select {
                    width: 100%;
                    margin: 40px 0 20px;
                }
                .title {
                    margin-top: 20px;
                    margin-bottom: 15px;
                    font-size: 18px;
                    color: #444444;
                }
                .city-list {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        cursor: pointer;
                        margin-right: 27px;
                        margin-bottom: 20px;
                        width: 48px;
                        height: 24px;
                        line-height: 24px;
                        background-color: #fff;
                        color: #38bc9d;
                        text-align: center;
                    }
                    li.selected {
                        background-color: #38bc9d;
                        color: #fff;
                    }
                }

                .input-error {
                    .el-input__inner {
                        border-color: #ff493c !important;
                    }
                }
                .other-city-input-error {
                    color: #ff493c;
                }
                .prompt-message {
                    margin: 20px 0;
                    font-size: 12px;
                    color: #999999;
                }
            }
        }
    }
}
</style>
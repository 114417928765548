var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "candidate-base-info-store-vue",
      attrs: { "data-spm-area-id": "A_candidate_detail_top" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading",
            },
          ],
          ref: "candidateBaseInfo",
          staticClass: "candidate-base-info",
          class: [
            !_vm.isFirmResume && !_vm.isCloudRecommendation
              ? "personal-resume"
              : "",
            _vm.isCloudRecommendation ? "cloud-resume" : "",
          ],
        },
        [
          _vm.lockStatus.isOfferStamp
            ? _c(
                "div",
                { staticClass: "offer-seal" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        placement: "right",
                        content: "已offer,请谨慎操作",
                        effect: "light",
                        "popper-class": "concat-tooltip",
                        "visible-arrow": false,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/candidateDetail/offer-seal.png"),
                          alt: "offer 印章",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isFirmResume && !_vm.isCloudRecommendation
            ? _c("img", {
                staticClass: "private-bg",
                attrs: {
                  src: require("@src/assets/images/candidateDetail/private-bg.png"),
                  alt: "私人简历",
                },
              })
            : _vm._e(),
          !_vm.isFirmResume && _vm.isCloudRecommendation
            ? _c("img", {
                staticClass: "private-bg",
                attrs: {
                  src: require("@src/assets/images/candidateDetail/cloud-bg.png"),
                  alt: "私人简历",
                },
              })
            : _vm._e(),
          _c(
            "el-row",
            { staticClass: "resume-headline" },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("p", { staticClass: "headline-base" }, [
                  _c(
                    "span",
                    {
                      staticClass: "headline-name",
                      attrs: { title: _vm.realName },
                    },
                    [_vm._v(_vm._s(_vm.realName || "未知"))]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.completionPercent,
                          expression: "completionPercent",
                        },
                      ],
                      staticClass: "completion-percent",
                    },
                    [
                      _c("i", { staticClass: "el-icon-star-on" }),
                      _c("a", {
                        staticClass: "resume-text",
                        attrs: { title: "完整度" },
                        domProps: {
                          textContent: _vm._s(_vm.completionPercent),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isJobHunting,
                          expression: "isJobHunting",
                        },
                      ],
                      staticClass: "job-hunting-tag",
                    },
                    [
                      _vm._v(
                        "\n                        求职\n                    "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.creatorRealName,
                          expression: "creatorRealName",
                        },
                      ],
                      staticClass: "headline-creator",
                    },
                    [
                      _c("avatar", {
                        staticClass: "headline-creator-avatar",
                        attrs: {
                          placement: "right",
                          enableCard: true,
                          enableLink: true,
                          shareData: _vm.shareData,
                          src: _vm.candidateDetail.creatorAvatarUrl,
                          userId: _vm.candidateDetail.creatorId,
                        },
                      }),
                      _c("i", { staticClass: "headline-title" }, [
                        _c("span", [_vm._v("创建者：")]),
                        _vm._v(
                          _vm._s(_vm.candidateDetail.creatorRealName) +
                            "@" +
                            _vm._s(_vm.candidateDetail.creatorNickname)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isFirmResume && _vm.existedResumeId,
                          expression: "!isFirmResume && existedResumeId",
                        },
                      ],
                      staticClass: "pull-right go-to-firm-resume",
                      attrs: {
                        href: `/#/candidateDetail/${_vm.existedResumeId}`,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        查看企业简历\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "headline-base" }, [
                  _vm.yearOfExperience && _vm.yearOfExperience != "未知"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.yearOfExperience) + "年工作经验"),
                      ])
                    : _c("span", [_vm._v("工作经验未知")]),
                  _vm.birthday && _vm.birthday.indexOf("0001-01-01") != 0
                    ? _c("i", { staticClass: "headline-border" }, [_vm._v("|")])
                    : _vm._e(),
                  _vm.birthday && _vm.birthday.indexOf("0001-01-01") != 0
                    ? _c("span", [
                        _vm._v(_vm._s(_vm._f("birthdayToAge")(_vm.birthday))),
                      ])
                    : _vm._e(),
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      staticClass: "headline-border",
                    },
                    [_vm._v("|")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.gender == "未知" ? "性别未知" : _vm.gender)
                      ),
                    ]
                  ),
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.cityName,
                          expression: "cityName",
                        },
                      ],
                      staticClass: "headline-border",
                    },
                    [_vm._v("|")]
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.cityName))]),
                  _c("i", { staticClass: "headline-border" }, [_vm._v("|")]),
                  _vm.degree
                    ? _c("span", { staticClass: "degree-text" }, [
                        _vm._v(_vm._s(_vm._f("getDegreeTextById")(_vm.degree))),
                      ])
                    : _c("span", { staticClass: "degree-text" }, [
                        _vm._v("学历不限"),
                      ]),
                  !_vm.isCloudRecommendation
                    ? _c("i", { staticClass: "headline-border" }, [_vm._v("|")])
                    : _vm._e(),
                  !_vm.isCloudRecommendation
                    ? _c("span", { staticClass: "headline-update" }, [
                        _c("i", { staticClass: "headline-title" }, [
                          _vm._v("更新时间："),
                        ]),
                        _vm._v(
                          _vm._s(_vm._f("formatDateWithDot")(_vm.updateTime))
                        ),
                      ])
                    : _vm._e(),
                  _vm.userInfo.isCFUser && _vm.isFirmResume
                    ? _c("span", { staticClass: "headline-point-tip" }, [
                        _vm._v("更新当前雇主公司和职位可得15积分"),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "candidate-headline" }, [
            _c("div", { staticClass: "candidate-headline-info-wrap" }, [
              _c("div", { staticClass: "candidate-headline-info" }, [
                _c(
                  "div",
                  { staticClass: "info-item" },
                  [
                    _vm.isFromAuthorization
                      ? [
                          _c("p", { staticClass: "resume-info" }, [
                            _c(
                              "i",
                              { staticClass: "headline-title" },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-telephone-out" },
                                }),
                                _vm._v("手机："),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              [
                                _c("span", { staticClass: "info-block" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.mobile || "******")),
                                  ]),
                                ]),
                                _vm.mobileStatus == 2
                                  ? [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            effect: "light",
                                            "popper-class": "concat-tooltip",
                                            "visible-arrow": false,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                候选人正在入职保证期中，暂不能查看其联"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "系方式\n                                            "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "info-lock-btn",
                                              attrs: {
                                                "data-event-id":
                                                  "E_candidate_contact_click",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                查看手机\n                                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm.mobileStatus == 1
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "info-block-btn",
                                          attrs: {
                                            loading: _vm.viewMobileLoading,
                                            type: "primary",
                                            size: "mini",
                                            "data-event-id":
                                              "E_candidate_contact_click",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.applyView(1)
                                            },
                                          },
                                        },
                                        [_vm._v("查看手机")]
                                      ),
                                    ]
                                  : [
                                      _vm.mobile == "******" ||
                                      _vm.mobile == null
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "info-block-btn",
                                              attrs: {
                                                loading: _vm.viewMobileLoading,
                                                type: "primary",
                                                size: "mini",
                                                "data-event-id":
                                                  "E_candidate_contact_click",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyView(1)
                                                },
                                              },
                                            },
                                            [_vm._v("查看手机")]
                                          )
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            ),
                          ]),
                        ]
                      : [
                          _vm.mobileStatus != 2 && !_vm.isCloudRecommendation
                            ? _c(
                                "p",
                                {
                                  staticClass: "resume-info",
                                  attrs: {
                                    "data-spm-e-data": _vm.getEData("Mobile"),
                                    "data-spm-e-id": "L_candidate_contact",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "headline-title" },
                                    [
                                      _c("font-icon", {
                                        attrs: { href: "#icon-telephone-out" },
                                      }),
                                      _vm._v("手机："),
                                    ],
                                    1
                                  ),
                                  _vm.mobileStatus == 3 || _vm.mobileStatus == 4
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "info-block" },
                                            [
                                              _c("span", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.enableHideMobile,
                                                    expression:
                                                      "!enableHideMobile",
                                                  },
                                                ],
                                                class:
                                                  _vm.mobileStatus == 4
                                                    ? "mobile-unable"
                                                    : "",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.mobile || "未知"
                                                  ),
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.enableHideMobile &&
                                                        !_vm.isFromAuthorization,
                                                      expression:
                                                        "enableHideMobile && !isFromAuthorization",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "authorization-mobile",
                                                },
                                                [_vm._v("手机号推荐后可见")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.enableHideMobile &&
                                                        _vm.isFromAuthorization,
                                                      expression:
                                                        "enableHideMobile && isFromAuthorization",
                                                    },
                                                  ],
                                                },
                                                [_vm._v(_vm._s(_vm.mobile))]
                                              ),
                                            ]
                                          ),
                                          _vm.canCall
                                            ? _c(
                                                "phone-call",
                                                {
                                                  ref: "phoneCall",
                                                  staticClass: "free-call",
                                                  attrs: {
                                                    "call-to": _vm.callTo,
                                                    "object-id":
                                                      _vm.candidateId || "",
                                                    proportion: _vm.proportion,
                                                    tbdResumeId: _vm.resumeId,
                                                    "is-firm-resume":
                                                      _vm.isFirmResume,
                                                    sourceFrom:
                                                      "candidateDetail",
                                                    "called-mobile": _vm.mobile,
                                                  },
                                                  on: {
                                                    "candidate-search-record-refresh":
                                                      _vm.handelRecordRefresh,
                                                  },
                                                },
                                                [
                                                  !(
                                                    !_vm.isFirmResume &&
                                                    !_vm.isCloudRecommendation
                                                  )
                                                    ? _c("el-button", [
                                                        _vm._v("拨打"),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.mobileStatus == 1 &&
                                  !_vm.isFromAuthorization
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "info-block-btn",
                                          attrs: {
                                            loading: _vm.viewMobileLoading,
                                            type: "primary",
                                            size: "mini",
                                            "data-event-id":
                                              "E_candidate_contact_click",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.applyView(1)
                                            },
                                          },
                                        },
                                        [_vm._v("查看手机")]
                                      )
                                    : _vm._e(),
                                  _vm.mobileStatus == 3 &&
                                  _vm.mobile &&
                                  !_vm.isFromAuthorization
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            content: "标记失效",
                                            placement: "bottom-start",
                                            "visible-arrow": false,
                                            "popper-class": "invalid-popper",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "invalid-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.markInvalid(
                                                    "mobile"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                staticClass: "mark-icon",
                                                attrs: {
                                                  href: "#icon-jinyong",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.mobileStatus == 4 &&
                                  _vm.mobile &&
                                  !_vm.isFromAuthorization
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            content: "激活号码",
                                            placement: "bottom-start",
                                            "visible-arrow": false,
                                            "popper-class": "invalid-popper",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "invalid-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.markValid("mobile")
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                staticClass: "activate-icon",
                                                attrs: { href: "#icon-jihuo" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isCloudRecommendation
                            ? _c("p", { staticClass: "resume-info" }, [
                                _c(
                                  "i",
                                  { staticClass: "headline-title" },
                                  [
                                    _c("font-icon", {
                                      attrs: { href: "#icon-telephone-out" },
                                    }),
                                    _vm._v("手机："),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.mobile) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm.mobileStatus == 2 &&
                          !_vm.isCloudRecommendation &&
                          !_vm.isFromAuthorization
                            ? _c(
                                "p",
                                {
                                  staticClass: "resume-info",
                                  attrs: {
                                    "data-spm-e-data": _vm.getEData("Mobile"),
                                    "data-spm-e-id": "L_candidate_contact",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "headline-title" },
                                    [
                                      _c("font-icon", {
                                        attrs: { href: "#icon-telephone-out" },
                                      }),
                                      _vm._v("手机："),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        effect: "light",
                                        "popper-class": "concat-tooltip",
                                        "visible-arrow": false,
                                      },
                                    },
                                    [
                                      _vm.userInfo.isAdministrator
                                        ? _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        候选人正在入职保证期中，联系方式已被上"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "锁，您可以进行解锁\n                                    "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        候选人正在入职保证期中，暂不能查看其联"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "系方式\n                                    "
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "info-lock-btn",
                                          attrs: {
                                            "data-event-id":
                                              "E_candidate_contact_click",
                                          },
                                          on: { click: _vm.unlockInfo },
                                        },
                                        [_vm._v("查看手机")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        effect: "light",
                                        "popper-class": "concat-tooltip",
                                        "visible-arrow": false,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        候选人正在入职保证期中，暂不能拨打其电话\n                                    "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        { staticClass: "free-call" },
                                        [_vm._v("拨打")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                    _vm.isFromAuthorization
                      ? [
                          _c(
                            "p",
                            { staticClass: "resume-info" },
                            [
                              _c(
                                "i",
                                { staticClass: "headline-title" },
                                [
                                  _c("font-icon", {
                                    attrs: { href: "#icon-email" },
                                  }),
                                  _vm._v("邮箱："),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "info-block",
                                  attrs: {
                                    title:
                                      _vm.email == "未知"
                                        ? "******"
                                        : _vm.email || "******",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.email == "未知"
                                          ? "******"
                                          : _vm.email || "******"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.emailStatus == 2
                                ? [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          effect: "light",
                                          "popper-class": "concat-tooltip",
                                          "visible-arrow": false,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            候选人正在入职保证期中，暂不能查看其联"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "系方式\n                                        "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "info-lock-btn",
                                            attrs: {
                                              "data-event-id":
                                                "E_candidate_contact_click",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            查看邮箱\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.emailStatus == 1
                                ? [
                                    _vm.emailStatus == 1
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "info-block-btn",
                                            attrs: {
                                              loading: _vm.viewEmailLoading,
                                              type: "primary",
                                              size: "mini",
                                              "data-event-id":
                                                "E_candidate_contact_click",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.applyView(2)
                                              },
                                            },
                                          },
                                          [_vm._v("查看邮箱")]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      : [
                          _vm.emailStatus != 2 && !_vm.isCloudRecommendation
                            ? _c(
                                "p",
                                {
                                  staticClass: "resume-info no-elli",
                                  attrs: {
                                    "data-spm-e-data": _vm.getEData("Email"),
                                    "data-spm-e-id": "L_candidate_contact",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "headline-title" },
                                    [
                                      _c("font-icon", {
                                        attrs: { href: "#icon-email" },
                                      }),
                                      _vm._v("邮箱："),
                                    ],
                                    1
                                  ),
                                  _vm.emailStatus == 3 || _vm.emailStatus == 4
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "info-block no-elli-wrap",
                                          attrs: { title: _vm.email },
                                        },
                                        [
                                          _vm.email == "未知"
                                            ? _c("span", {
                                                staticClass: "email-text",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.email
                                                  ),
                                                },
                                              })
                                            : _c("a", {
                                                class:
                                                  _vm.emailStatus == 4 &&
                                                  _vm.email !== "未知"
                                                    ? "email-unable"
                                                    : "email-able",
                                                attrs: {
                                                  href:
                                                    _vm.emailStatus == 4
                                                      ? "javascript:;"
                                                      : `mailto:${_vm.email}`,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.email
                                                  ),
                                                },
                                              }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.emailStatus == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "info-block-btn",
                                          attrs: {
                                            loading: _vm.viewEmailLoading,
                                            type: "primary",
                                            size: "mini",
                                            "data-event-id":
                                              "E_candidate_contact_click",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.applyView(2)
                                            },
                                          },
                                        },
                                        [_vm._v("查看邮箱")]
                                      )
                                    : _vm._e(),
                                  _vm.email !== "未知"
                                    ? _c(
                                        "el-button",
                                        { on: { click: _vm.onShowMailDialog } },
                                        [_vm._v("发邮件")]
                                      )
                                    : _vm._e(),
                                  _vm.emailStatus == 3 &&
                                  _vm.email !== "未知" &&
                                  !_vm.isFromAuthorization
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            content: "标记失效",
                                            placement: "bottom-start",
                                            "visible-arrow": false,
                                            "popper-class": "invalid-popper",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "invalid-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.markInvalid(
                                                    "email"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                staticClass: "mark-icon",
                                                attrs: {
                                                  href: "#icon-jinyong",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.emailStatus == 4 &&
                                  _vm.email !== "未知" &&
                                  !_vm.isFromAuthorization
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            content: "激活邮箱",
                                            placement: "bottom-start",
                                            "visible-arrow": false,
                                            "popper-class": "invalid-popper",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "invalid-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.markValid("email")
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                staticClass: "activate-icon",
                                                attrs: { href: "#icon-jihuo" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isCloudRecommendation
                            ? _c("p", { staticClass: "resume-info" }, [
                                _c(
                                  "i",
                                  { staticClass: "headline-title" },
                                  [
                                    _c("font-icon", {
                                      attrs: { href: "#icon-email" },
                                    }),
                                    _vm._v("邮箱："),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.email) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm.emailStatus == 2 && !_vm.isCloudRecommendation
                            ? _c(
                                "p",
                                {
                                  staticClass: "resume-info",
                                  attrs: {
                                    "data-spm-e-data": _vm.getEData("Email"),
                                    "data-spm-e-id": "L_candidate_contact",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "headline-title" },
                                    [
                                      _c("font-icon", {
                                        attrs: { href: "#icon-email" },
                                      }),
                                      _vm._v("邮箱："),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        effect: "light",
                                        "popper-class": "concat-tooltip",
                                        "visible-arrow": false,
                                      },
                                    },
                                    [
                                      _vm.userInfo.isAdministrator
                                        ? _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        候选人正在入职保证期中，联系方式已被上"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "锁，您可以进行解锁\n                                    "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        候选人正在入职保证期中，暂不能查看其联"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "系方式\n                                    "
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "info-lock-btn",
                                          attrs: {
                                            "data-event-id":
                                              "E_candidate_contact_click",
                                          },
                                          on: { click: _vm.unlockInfo },
                                        },
                                        [_vm._v("查看邮箱")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                    _vm.isFromAuthorization
                      ? [
                          _c("p", { staticClass: "resume-info" }, [
                            _c(
                              "i",
                              { staticClass: "headline-title" },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-xiaoxi-zhihuibeifen" },
                                }),
                                _vm._v("微信："),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              [
                                _c("span", { staticClass: "info-block" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.wechat || "未知")),
                                  ]),
                                ]),
                                _vm.wechatStatus == 2 &&
                                (_vm.wechat == "" || _vm.wechat == "******")
                                  ? [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            effect: "light",
                                            "popper-class": "concat-tooltip",
                                            "visible-arrow": false,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                候选人正在入职保证期中，暂不能查看其联"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "系方式\n                                            "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "info-lock-btn",
                                              attrs: {
                                                "data-event-id":
                                                  "E_candidate_contact_click",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                查看微信\n                                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.wechatStatus == 1
                                  ? [
                                      _vm.wechatStatus == 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "info-block-btn",
                                              attrs: {
                                                loading: _vm.viewWechatLoading,
                                                type: "primary",
                                                size: "mini",
                                                "data-event-id":
                                                  "E_candidate_contact_click",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyView(3)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            查看微信\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _vm.wechat !== "未知" && _vm.wechatStatus != 3
                                  ? _c("el-button", { ref: "copyWechatBtn" }, [
                                      _vm._v(
                                        "\n                                        复制微信\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]
                      : [
                          _vm.wechatStatus != 2 && !_vm.isCloudRecommendation
                            ? _c(
                                "p",
                                {
                                  staticClass: "resume-info",
                                  attrs: {
                                    "data-spm-e-data": _vm.getEData("Wechat"),
                                    "data-spm-e-id": "L_candidate_contact",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "headline-title" },
                                    [
                                      _c("font-icon", {
                                        attrs: {
                                          href: "#icon-xiaoxi-zhihuibeifen",
                                        },
                                      }),
                                      _vm._v("微信："),
                                    ],
                                    1
                                  ),
                                  _vm.wechatStatus == 3 || _vm.wechatStatus == 4
                                    ? _c(
                                        "span",
                                        { staticClass: "info-block" },
                                        [
                                          _c("span", {
                                            class:
                                              _vm.wechatStatus == 4
                                                ? "wechat-unable"
                                                : "",
                                            domProps: {
                                              textContent: _vm._s(_vm.wechat),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.wechatStatus == 2 &&
                                  (_vm.wechat == "" || _vm.wechat == "******")
                                    ? [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              placement: "bottom",
                                              effect: "light",
                                              "popper-class": "concat-tooltip",
                                              "visible-arrow": false,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            候选人正在入职保证期中，暂不能查看其联"
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "系方式\n                                        "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "info-lock-btn",
                                                attrs: {
                                                  "data-event-id":
                                                    "E_candidate_contact_click",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            查看微信\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.wechatStatus == 1
                                    ? [
                                        _vm.wechatStatus == 1
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "info-block-btn",
                                                attrs: {
                                                  loading:
                                                    _vm.viewWechatLoading,
                                                  type: "primary",
                                                  size: "mini",
                                                  "data-event-id":
                                                    "E_candidate_contact_click",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.applyView(3)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        查看微信\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                  _vm.wechat !== "未知" &&
                                  _vm.wechat != "" &&
                                  _vm.wechat != "******"
                                    ? _c(
                                        "el-button",
                                        { ref: "copyWechatBtn" },
                                        [
                                          _vm._v(
                                            "\n                                    复制微信\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.wechatStatus == 3 &&
                                  _vm.wechat !== "未知" &&
                                  !_vm.isFromAuthorization
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            content: "标记失效",
                                            placement: "bottom-start",
                                            "visible-arrow": false,
                                            "popper-class": "invalid-popper",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "invalid-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.markInvalid(
                                                    "wechat"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                staticClass: "mark-icon",
                                                attrs: {
                                                  href: "#icon-jinyong",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.wechatStatus == 4 &&
                                  _vm.wechat !== "未知" &&
                                  !_vm.isFromAuthorization
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            content: "激活微信",
                                            placement: "bottom-start",
                                            "visible-arrow": false,
                                            "popper-class": "invalid-popper",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "invalid-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.markValid("wechat")
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                staticClass: "activate-icon",
                                                attrs: { href: "#icon-jihuo" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.isCloudRecommendation
                            ? _c("p", { staticClass: "resume-info" }, [
                                _c(
                                  "i",
                                  { staticClass: "headline-title" },
                                  [
                                    _c("font-icon", {
                                      attrs: {
                                        href: "#icon-xiaoxi-zhihuibeifen",
                                      },
                                    }),
                                    _vm._v("微信："),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.wechat) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm.wechatStatus == 2 && !_vm.isCloudRecommendation
                            ? _c(
                                "p",
                                {
                                  staticClass: "resume-info",
                                  attrs: {
                                    "data-spm-e-data": _vm.getEData("Wechat"),
                                    "data-spm-e-id": "L_candidate_contact",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "headline-title" },
                                    [
                                      _c("font-icon", {
                                        attrs: {
                                          href: "#icon-xiaoxi-zhihuibeifen",
                                        },
                                      }),
                                      _vm._v("微信："),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "bottom",
                                        effect: "light",
                                        "popper-class": "concat-tooltip",
                                        "visible-arrow": false,
                                      },
                                    },
                                    [
                                      _vm.userInfo.isAdministrator
                                        ? _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        候选人正在入职保证期中，联系方式已被上"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "锁，您可以进行解锁\n                                    "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        候选人正在入职保证期中，暂不能查看其联"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "系方式\n                                    "
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "info-lock-btn",
                                          attrs: {
                                            "data-event-id":
                                              "E_candidate_contact_click",
                                          },
                                          on: { click: _vm.unlockInfo },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        查看微信\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
                _c("div", { staticClass: "info-item" }, [
                  _c("p", { staticClass: "school" }, [
                    _c(
                      "i",
                      { staticClass: "headline-title" },
                      [_c("font-icon", { attrs: { href: "#icon-work" } })],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "school-text",
                        attrs: { title: _vm.school },
                      },
                      [_vm._v(_vm._s(_vm.school || "学校未知"))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "major-text",
                        attrs: { title: _vm.major },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.major ? `-${_vm.major}` : "-专业未知")
                        ),
                      ]
                    ),
                    _vm.degree
                      ? _c("span", { staticClass: "degree-text" }, [
                          _vm._v(
                            _vm._s(_vm._f("getDegreeTextById")(_vm.degree))
                          ),
                        ])
                      : _c("span", { staticClass: "degree-text" }, [
                          _vm._v("学历不限"),
                        ]),
                  ]),
                  _c("p", { staticClass: "company" }, [
                    _c(
                      "i",
                      { staticClass: "headline-title" },
                      [
                        _c("font-icon", {
                          attrs: { href: "#icon-lieqishezhibeifen" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "company-text",
                        attrs: { title: _vm.company },
                      },
                      [_vm._v(_vm._s(_vm.company || "公司未知"))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "title-text",
                        attrs: { title: _vm.candidateTitle },
                      },
                      [_vm._v(_vm._s(_vm.candidateTitle || "职位未知"))]
                    ),
                  ]),
                  _vm.isFirmResume && !!_vm.customerDepartmentName
                    ? _c(
                        "p",
                        {
                          staticClass: "company",
                          attrs: { title: _vm.customerDepartmentName },
                        },
                        [
                          _c(
                            "i",
                            { staticClass: "headline-title" },
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-lieqishezhibeifen" },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "department-text" }, [
                            _vm._v(_vm._s(_vm.customerDepartmentName)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.isFirmResume
                    ? _c("p", { staticClass: "new-first-city-chance" }, [
                        _c(
                          "i",
                          { staticClass: "headline-title" },
                          [
                            _c("font-icon", {
                              attrs: { href: "#icon-offerbeifen" },
                            }),
                            _vm._v("新一线机会："),
                          ],
                          1
                        ),
                        _vm.isCFuser && !_vm.newCityExpectationsInfo?.id
                          ? _c(
                              "span",
                              {
                                staticClass: "first-time",
                                on: { click: _vm.addNewFirstCity },
                              },
                              [_vm._v("首次填写，加10积分")]
                            )
                          : _vm._e(),
                        !_vm.isCFuser && !_vm.newCityExpectationsInfo?.id
                          ? _c(
                              "span",
                              {
                                staticClass: "first-time",
                                on: { click: _vm.addNewFirstCity },
                              },
                              [_vm._v("填写")]
                            )
                          : _c(
                              "span",
                              { staticClass: "new-first-city-content" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "city-text",
                                    attrs: {
                                      title: _vm.newCityExpectationsText,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.newCityExpectationsText))]
                                ),
                                _vm.isCFuser && _vm.newCityExpectationsInfo?.id
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "option modification",
                                        on: { click: _vm.editNewFirstCity },
                                      },
                                      [
                                        _c("font-icon", {
                                          attrs: { href: "#icon-bianji" },
                                        }),
                                        _vm._v(
                                          "修改\n                                "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "option view-log",
                                    on: { click: _vm.viewLogs },
                                  },
                                  [
                                    _c("font-icon", {
                                      attrs: { href: "#icon-xiangmujingli-04" },
                                    }),
                                    _vm._v(
                                      "查看日志\n                                "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                      ])
                    : _c("p"),
                ]),
              ]),
              !_vm.isCloudRecommendation
                ? _c(
                    "div",
                    { staticClass: "resume-label" },
                    [
                      _c("candidate-labels", {
                        ref: "candidateLabels",
                        attrs: { resumeId: _vm.resumeId },
                        on: {
                          "collect-label-refresh": _vm.handleCollectLabel,
                          showLabelManagement: _vm.showLabelManagement,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            !_vm.isCloudRecommendation
              ? _c("div", { staticClass: "candidate-headline-option-wrap" }, [
                  _c("div", { staticClass: "candidate-headline-option" }, [
                    _vm.isCloudRecommendation
                      ? _c(
                          "div",
                          {
                            staticClass: "option-btn resume-edit-btn",
                            on: { click: _vm.onEmailShare },
                          },
                          [
                            _c("font-icon", {
                              staticClass: "candidate-icon",
                              attrs: { href: "#icon-ic_transmit_xq" },
                            }),
                            _c("span", { staticClass: "edit-text" }, [
                              _vm._v("转发"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isCloudRecommendation
                      ? _c(
                          "div",
                          {
                            staticClass: "option-btn resume-edit-btn",
                            on: { click: _vm.onPrint },
                          },
                          [
                            _c("font-icon", {
                              staticClass: "candidate-icon",
                              attrs: { href: "#icon-ic_print_xq" },
                            }),
                            _c("span", { staticClass: "edit-text" }, [
                              _vm._v("打印"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isCloudRecommendation
                      ? _c("div", { staticClass: "option-btn collect-btn" }, [
                          _c(
                            "span",
                            {
                              staticClass: "clollect-text",
                              on: {
                                mouseover: _vm.showCollectPopup,
                                click: _vm.onCandidateCollect,
                              },
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "option-clollect-popper",
                                  attrs: {
                                    effect: "light",
                                    content: _vm.isCollected
                                      ? "取消收藏"
                                      : "加入Talent Pool",
                                    placement: "top",
                                    "visible-arrow": false,
                                    "popper-options": {
                                      boundariesElement: "body",
                                    },
                                    "popper-class": "candidate-collect-popper",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _vm.isCollected
                                        ? _c("font-icon", {
                                            staticClass:
                                              "candidate-icon isCollected",
                                            attrs: {
                                              href: "#icon-a-rencaiyishoucang",
                                            },
                                          })
                                        : _c("font-icon", {
                                            staticClass: "candidate-icon",
                                            attrs: {
                                              href: "#icon-shoucang_fill",
                                            },
                                          }),
                                      _c("span", { staticClass: "text" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isCollected ? "已收藏" : "收藏"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ifCollectPopupShow,
                                  expression: "ifCollectPopupShow",
                                },
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.collectLoading,
                                  expression: "collectLoading",
                                },
                              ],
                              staticClass: "collect-lable-popup",
                            },
                            [
                              _c("span", { staticClass: "arrow-up" }),
                              _vm._m(0),
                              _c("p", [_vm._v("已收藏")]),
                              _c("p", { staticClass: "text-grey" }, [
                                _vm._v("添加标签，轻松分类人才"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "label-lists" },
                                _vm._l(
                                  _vm.candidateLabels,
                                  function (item, index) {
                                    return _c("span", {
                                      key: index,
                                      class: [
                                        "label-item",
                                        { active: item.isSelected },
                                      ],
                                      attrs: {
                                        title: item.name,
                                        isselected: item.isSelected,
                                      },
                                      domProps: {
                                        textContent: _vm._s(item.name),
                                      },
                                      on: { click: _vm.onLabelClick },
                                    })
                                  }
                                ),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isLabelInputVisible,
                                      expression: "!isLabelInputVisible",
                                    },
                                  ],
                                  staticClass: "add-new-label",
                                  on: { click: _vm.setLabelInputVisible },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-font icon-tianjia",
                                  }),
                                  _vm._v(
                                    "\n                                添加新标签\n                            "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isLabelInputVisible,
                                      expression: "isLabelInputVisible",
                                    },
                                  ],
                                  staticClass: "add-label-input",
                                },
                                [
                                  _c("input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "输入新标签",
                                      id: "newLabelName",
                                      maxlength: "10",
                                    },
                                    on: { keydown: _vm.onLabelInputKeydown },
                                  }),
                                  _c("i", {
                                    staticClass:
                                      "icon-font icon-rec-add icon-add-marign",
                                    on: { click: _vm.addNewLabel },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    (_vm.isFirmResume || _vm.isCloudRecommendation) &&
                    _vm.longUrl &&
                    !_vm.isFromAuthorization
                      ? _c(
                          "div",
                          {
                            staticClass: "option-btn wx-code",
                            on: { click: _vm.handleShare },
                          },
                          [
                            _c("font-icon", {
                              staticClass: "candidate-icon",
                              attrs: { href: "#icon-gongjulan-yaoqinghaoyou" },
                            }),
                            _c("span", [_vm._v("分享")]),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isCloudRecommendation && !_vm.isFromAuthorization
                      ? _c(
                          "div",
                          {
                            staticClass: "option-btn resume-edit-btn",
                            on: { click: _vm.onResumeEdit },
                          },
                          [
                            _c("font-icon", {
                              staticClass: "candidate-icon",
                              attrs: { href: "#icon-edit1" },
                            }),
                            _c("span", { staticClass: "edit-text" }, [
                              _vm._v("编辑"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isFirmResume &&
                    _vm.userInfo.isCFUser &&
                    _vm.userInfo.isAdministrator &&
                    !_vm.isFromAuthorization &&
                    _vm.isHiding
                      ? _c(
                          "div",
                          {
                            staticClass: "option-btn wx-code hide-resume-icon",
                            on: { click: _vm.handleCancelHideResume },
                          },
                          [
                            _c("font-icon", {
                              staticClass: "candidate-icon",
                              attrs: { href: "#icon-chakan" },
                            }),
                            _c("span", [_vm._v("显示")]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isFirmResume &&
                    _vm.userInfo.isCFUser &&
                    _vm.userInfo.isAdministrator &&
                    !_vm.isFromAuthorization &&
                    !_vm.isHiding
                      ? _c(
                          "div",
                          {
                            staticClass: "option-btn wx-code hide-resume-icon",
                            on: { click: _vm.handleHideResume },
                          },
                          [
                            _c("font-icon", {
                              staticClass: "candidate-icon",
                              attrs: { href: "#icon-chakan" },
                            }),
                            _c("span", [_vm._v("隐藏")]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn-list",
                      class: _vm.candidateDetailData.isFromAuthorization
                        ? "btn-list-authorization"
                        : "",
                    },
                    [
                      _c("div", { staticClass: "btn-list-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn btn-hollow",
                            on: { click: _vm.onShowRemindMeDialog },
                          },
                          [
                            _c("font-icon", {
                              attrs: { href: "#icon-xiaoxizhongxin" },
                            }),
                            _c("span", [_vm._v("添加事项提醒")]),
                          ],
                          1
                        ),
                        _vm.candidateDetailData.isFirm
                          ? _c(
                              "div",
                              {
                                staticClass: "btn btn-hollow",
                                on: { click: _vm.communicationJobs },
                              },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-time1" },
                                }),
                                _c("span", [_vm._v("加入待沟通")]),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.addCompanyLoading,
                                    expression: "addCompanyLoading",
                                  },
                                ],
                                staticClass: "btn btn-hollow btn-resume-grab",
                                on: {
                                  click: function ($event) {
                                    return _vm.addToCompany(
                                      _vm.candidateDetailData.resumeGrabType
                                    )
                                  },
                                },
                              },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon--shangchuan" },
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.candidateDetailData.resumeGrabType ==
                                        2 ||
                                        _vm.candidateDetailData
                                          .resumeGrabType === 0
                                        ? "更新企业库简历"
                                        : "导入企业库"
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.addTrackingListLoading,
                                expression: "addTrackingListLoading",
                              },
                            ],
                            staticClass: "btn btn-hollow",
                            on: {
                              click: function ($event) {
                                return _vm.handleAddTrackingList(
                                  _vm.candidateDetailData.isAddToTrackingList
                                )
                              },
                            },
                          },
                          [
                            _c("font-icon", { attrs: { href: "#icon-user1" } }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.candidateDetailData.isAddToTrackingList
                                    ? "移除Tracking List"
                                    : "加入Tracking List"
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        !_vm.candidateDetailData.isFromAuthorization
                          ? _c(
                              "div",
                              {
                                staticClass: "btn btn-hollow",
                                on: { click: _vm.onCandidateArticleEdit },
                              },
                              [
                                _c("font-icon", {
                                  staticClass: "candidate-icon",
                                  attrs: { href: "#icon-edit1" },
                                }),
                                _c("span", [_vm._v("编辑简历")]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "recommend-btn",
                            on: { click: _vm.recommendJobs },
                          },
                          [_vm._v("推荐")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inquire-btn",
                            on: { click: _vm.inquiryShow },
                          },
                          [_vm._v("添加寻访")]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("new-first-city-dialog", {
        ref: "newFirstCityDialog",
        attrs: {
          tbdResumeId: _vm.resumeId,
          "candidate-id": _vm.candidateId,
          newCityExpectationsInfo: _vm.newCityExpectationsInfo,
        },
        on: { "refresh-new-city-expectations": _vm.refreshNewCityExpectations },
      }),
      _c("edit-new-city-logs-dialog", {
        ref: "editNewCityLogsDialog",
        attrs: { tbdResumeId: _vm.resumeId },
      }),
      _c("inquiry-dialog", {
        ref: "inquiryDialog",
        attrs: { "source-from": 5 },
        on: {
          "refresh-detail": _vm.refreshdetail,
          "update-item": _vm.RecordListRefresh,
        },
      }),
      _c("add-communication-dialog", {
        ref: "addCommunicationDialog",
        on: { "update-item": _vm.handelTabRefresh },
      }),
      _c("import-result-dialog", {
        ref: "importResultDialog",
        on: {
          "import-confirm": _vm.handleImportConfirm,
          "import-then-compare": _vm.goToCompare,
        },
      }),
      _c("create-notice-dialog", { ref: "createNoticeDialog" }),
      _c("recommend-dialog", { ref: "recommendDialog", attrs: { type: 0 } }),
      _c("mail-dialog", {
        ref: "mailDialog",
        attrs: {
          "candidate-id": _vm.candidateId,
          "candidate-email": _vm.email,
          "candidate-name": _vm.realName,
        },
      }),
      _c("base-info-edit-dialog", {
        ref: "baseInfoEditDialog",
        attrs: { isPersonalResume: !_vm.isFirmResume },
        on: {
          "candidate-detail-refresh": function ($event) {
            return _vm.$emit("candidate-detail-refresh")
          },
          "apply-view-success": _vm.handleApplyViewSuccess,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.unlockLoading,
              expression: "unlockLoading",
            },
          ],
          attrs: {
            title: "提示",
            visible: _vm.unlockDialog,
            width: "520px",
            "custom-class": "unlock-info-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.unlockDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("p", { staticClass: "dialog-content-tips" }, [
              _vm._v(
                "候选人还在下面客户的入职保证期阶段，确定解除联系方式的锁定?"
              ),
            ]),
            _c(
              "div",
              { staticClass: "customer-list" },
              _vm._l(_vm.customerList, function (customer, index) {
                return _c("span", { key: index, staticClass: "list-item" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        `${customer.company} ${customer.jobName}[${customer.locationName}]`
                      ) +
                      "\n                "
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.unlockDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleUnlock } },
                [_vm._v("确定解锁")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.markInvalidLoading,
              expression: "markInvalidLoading",
            },
          ],
          attrs: {
            title: "提示",
            visible: _vm.markInvalidDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.markInvalidDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _vm._v(
              "\n            确定将该" +
                _vm._s(_vm.contactText) +
                "标记为无效吗？\n        "
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.markInvalidDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleMarkInvalid },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.markValidLoading,
              expression: "markValidLoading",
            },
          ],
          attrs: {
            title: "提示",
            visible: _vm.markValidDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.markValidDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _vm._v(
              "\n            确定激活该" +
                _vm._s(_vm.contactText) +
                "吗？\n        "
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.markValidDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleMarkValid },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("email-share", {
        ref: "emailShare",
        attrs: { recommendationId: _vm.recommendationid },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "share-dialog",
            title: "分享",
            visible: _vm.shareDialogShow,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.shareDialogShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", { on: { click: _vm.showShareToImDialog } }, [
              _c("img", {
                attrs: {
                  src: require("#/assets/images/instantMessage/private_message.png"),
                },
              }),
              _vm._v("\n                私信和群分享\n            "),
            ]),
            _c("div", { on: { click: _vm.showShareToWechatDialog } }, [
              _c("img", {
                attrs: {
                  src: require("#/assets/images/instantMessage/wechat.png"),
                },
              }),
              _vm._v("\n                微信好友\n            "),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "share-to-wechat-dialog",
            title: "分享",
            visible: _vm.shareToWechatDialogShow,
            width: "500px",
            "before-close": _vm.handleCloseshareToWechatDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.shareToWechatDialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("img", {
                attrs: { width: "118", height: "118", src: _vm.qrcodeUrl },
              }),
              _c("p", [_vm._v("打开微信扫一扫")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.handleCloseshareToWechatDialog },
                },
                [_vm._v("完成")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "hide-resume-dialog",
            title: "选择隐藏时间",
            visible: _vm.hideResumeDialogShow,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.hideResumeDialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("p", { staticClass: "hide-resume-dialog-text" }, [
                _vm._v("时间"),
              ]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "start-placeholder": "开始日期",
                  "range-separator": "-",
                  "end-placeholder": "结束日期",
                  editable: false,
                  align: "right",
                  "value-format": "yyyy-MM-dd",
                  clearable: false,
                  "picker-options": _vm.expireTimeOption,
                  "popper-class": "performance-data-range",
                },
                on: { change: _vm.handleHideDate },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelHideResume } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.hideResumeLoading,
                      expression: "hideResumeLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmHideResume },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("label-management", {
        ref: "labelManagement",
        on: { labelManagementClose: _vm.labelManagementClose },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("i", { staticClass: "icon-agree icon-font" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }
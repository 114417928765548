var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ko-before" }, [
    _c("div", { class: ["candidate-resume"] }, [
      _c(
        "div",
        [
          _c("candidate-resume-detail", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeValue == 1,
                expression: "activeValue == 1",
              },
            ],
            ref: "candidateResumeDetail",
            staticClass: "card",
            attrs: {
              canEdit: _vm.canEdit,
              "candidate-id": _vm.candidateId,
              "is-firm": _vm.isFirm,
              "resume-id": _vm.resumeId,
            },
            on: { "candidate-detail-refresh": _vm.candidatedetailrefresh },
          }),
          _c("comments", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.activeValue == 4 &&
                  ((_vm.canEdit && _vm.isFirm) || _vm.isCloudRecommendation),
                expression:
                  "activeValue == 4 && ((canEdit && isFirm) || isCloudRecommendation)",
              },
            ],
            ref: "comments",
            staticClass: "card candidate-resume-comments",
            attrs: {
              isEmail: true,
              team: true,
              prefetch: false,
              postId: _vm.candidateId,
              commentType: "candidate",
              commentObjectInfo: _vm.candidateDetailData,
              placeholderText: "关于候选人的互动交流",
              shareData: _vm.shareData,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div v-loading="loading">
    <div class="candidate-recommendation-detail">
        <div class="top-wrap">
            <candidate-recommendation-header
                ref="candidateRecommendationHeader"
                class="card"
                @candidate-search-record-refresh='handelRecordRefresh'
                @router-navigate="handleRouterNavigate"
                :resume-id="resumeId"
                :shareData="shareData"
                :currentJobType="curJobType"
                :recommendDate="recommendDate"
                :is-cloud-recommendation="isCloudRecommendation"
            ></candidate-recommendation-header>
            <div
                class="recommend-attachment"
                v-show="hasRecommendAttachment"
            >   
                <!-- v-show="(isRecommender || isPublisher) && hasRecommendAttachment" -->
                <!-- <span class="recommend-attachment-title">
                    推荐上传附件：
                </span> -->
                <attachment-list
                    ref="attachmentList"
                    :recommendationId="recommendationId"
                    :candidateName="shareData ? shareData.candidateName : ''"
                    :torecommends="false"
                    type="recommend"
                    @has-attachment="showRecommendAttachment"
                ></attachment-list>
            </div>
        </div>
        <div class="recommendation-detail-content">
            <div class="blank-block">
                <div class="tit-cell">
                    <span class="tit">当前状态</span>
                    <span class="line"></span>
                </div>
                <div class="content">
                    <recommend-process
                        ref="recommendProcess"
                        :recommendationId="recommendationId"
                        class="card"
                        @recommend-process-success="handleProcessSuccess"
                        @timelineRefresh="timelineRefresh"
                        @comment-at-and-tip="AtandTip"
                        @similar-resume='handelSimilarResume'
                        @transfer="handleEmailShare"
                        @successInterview="interviewSuccess"
                        @successEditOffer="offerEditSuccess"
                        @attachmentRefresh="attachmentRefresh"
                        :isPmOrHrPage="[3,5, 4].includes(curJobType)"
                    ></recommend-process>
                </div>
            </div>

            <!-- 人才详情不显示推荐评价 -->
            <!-- <div class="blank-block" id="recommendEvaluate">
                <div class="tit-cell">
                    <span class="tit">推荐评价</span>
                    <span class="line"></span>
                </div>
                <div class="content">
                    <recomand-comment 
                        ref="recomandComment"
                        commentType="job"
                        placeholderText="请对该条推荐做出你的评价"
                        class="card"
                        :isEmail="true"
                        :team="true"
                        :prefetch="true"
                        :shareData="shareData"
                        :canEvalute="canEvalute"
                        @is-evalute="isEvalute"
                    ></recomand-comment>
                </div>
            </div> -->
            <div class="blank-block" id="timeline">
                <div class="tit-cell">
                    <span class="tit">时间线</span>
                    <span class="line"></span>
                </div>
                <div class="content">
                    <candidate-recommendation-timeline
                        ref="candidateRecommendationTimeline"
                        class="card"
                        :recommendationId="recommendationId"
                        :isPublisher="isPublisher"
                        @show-offer-dialog="showOfferDialog"
                    ></candidate-recommendation-timeline>
                </div>
            </div>
        </div>
        <!-- <div class="col-16">
            <candidate-article ref="candidateArticle" class="card" :recommendationId="recommendationId" :isPublisher="isPublisher" :isRecommender="isRecommender" >
                <template v-slot:article-extra>
                    <div class="document-list">
                        <attachment-list ref="attachmentList" prefetch="false" :recommendationId="recommendationId" :torecommends="false" type="recommend"></attachment-list>
                    </div>
                </template>
                <template v-slot:article-header>
                    简历
                    <div class="right-opr">
                        <spinner ref="uploadSpinner"></spinner>
                        <el-upload ref="el-upload" class="resume-upload" :action="uploadUrl" :data="token" :show-file-list="false" :on-success="handleUploadSuccess" :on-error="handleUploadError" :before-upload="handleBeforeUpload">
                            <i class="icon-font icon-upload">
                                <span>上传内部附件</span>
                            </i>
                        </el-upload>
                        <span class="btn-edit-wrap" v-show="mycanEdit">
                            <a class="edit-link btn-edit" :href="'/My/Candidate/Create?candidateId='+candidateId" target="_blank" >编辑</a>
                        </span>
                    </div>
                    <btn ref="print" class="print pull-right print-margin" @on-click="handlePrint" id="print" icon="print" v-show="isPublisher" :text="'打印'"></btn>
                    <span class="wechat-share pull-right">
                        <btn id="wechat-share" :icon="'qrcode'" :text="'分享'"></btn>
                        <popover trigger="hover" :content="qrcodePopoverTpl" :enableHtml="true" placement="bottom"></popover>
                    </span>
                    <btn ref="emailShare" class="email-share pull-right email-share-margin" @on-click="handleEmailShare" id="emailShare" icon="share-square-o" v-show="isPublisher" :text="'转发'"></btn>
                </template>
            </candidate-article>
            <comments ref="comments" :isEmail="true" :team="true" class="card comments-print" :prefetch="false" :postId="recommendationId" commentType="recommendation" placeholderText="沟通面试安排，讨论候选人情况。还可以@他人昵称试试"></comments>
        </div> -->
        <!-- <div>
            <candidate-recommendation-reader-list ref="candidateRecommendationReaderList" :recommendationId="recommendationId" class="card"></candidate-recommendation-reader-list>
        </div> -->
        <candidate-recommendation-similar-resume
            ref="candidateRecommendationSimilarResume"
        ></candidate-recommendation-similar-resume>

        <candidate-email-share
            ref="candidateEmailShare"
            :recommendationId="recommendationId"
            @emailshare-success='handelReaderList'
        ></candidate-email-share>
        <!-- <candidate-print ref="candidatePrint"></candidate-print> -->
    </div>
</div>
</template>

<script>
    // import comments from '../comments/index';
    // import candidateArticle from '../candidate-article/index';
    // import candidateRecommendationReaderList from '../candidate-recommendation-reader-list/index';

    import { candidate as candidateConfig } from '#/js/config/api.json';
    
    import attachmentList from './attachmentList.vue';
    // import recommendProcess from './recommend-process/index';
    import recommendProcess from './recommend-process/index-refactor.vue';
    // import candidateEmailShare from './candidate-email-share/index';
    import candidateEmailShare from './candidateEmailShareDialog.vue';
    import CandidateRecommendationHeader from './candidate-recommendation-header/index';
    import candidateRecommendationTimeline from './candidate-recommendation-timeline/index';
    import candidateRecommendationSimilarResume from './candidateRecommendationSimilarResume.vue';
    import recomandComment from '#/component/recomand-evaluate/comments.vue'
    import { job as jobUrl } from '#/js/config/api.json';
    
    export default {
        components: {
            CandidateRecommendationHeader,
            // candidateArticle,
            candidateRecommendationTimeline,
            // candidateRecommendationReaderList,
            candidateEmailShare,
            candidateRecommendationSimilarResume,
            // comments,
            attachmentList,
            // btn,
            // popover,
            recommendProcess,
            // candidatePrint,
            // Spinner
            recomandComment,
        },
        computed:{
            token(){
                return {__RequestVerificationToken:this.$store.state.verificationToken.verificationToken};
            },
            uploadUrl(){
                // return placeholder(candidateConfig.url_add_recommendation_attachment, this.recommendationId)
                return candidateConfig.url_add_recommendation_attachment.replace(/%p/, this.recommendationId);
            },
            isAdministrator() {
                return this.$store.state.user.userInfo.isAdministrator || false;
            }
        },
        data() {
            return {
                curJobType: undefined, // 当前职位类型
                isPublisher: false,
                isRecommender: true,
                // qrcodePopoverTpl: '',
                myrecommendationId:this.recommendationId,
                mycanEdit:this.canEdit,
                loading:false,
                hasRecommendAttachment: false,
                publisherInfo: null,

                hasEvalute: false,
                canEvalute: false,
                recommendDate: '',
            }
        },
        props: {
            recommendationId: {
                type: String,
                default: ''
            },
            recommendation: {
                type: Object,
                default:{}
            },
            candidateId: String,
            canEdit:{
                type:Boolean,
                default:false
            },
            resumeId:String,
            shareData: Object,
            isCloudRecommendation: {
                type: Boolean,
                default: false
            },

        },
        watch: {
            recommendationId: function(val) {
                    this.myrecommendationId = val;
                }
        },
        mounted () {
            this.attachmentList = this.$refs.attachmentList;
            // this.comments = this.$refs.comments;
            this.recommendProcess = this.$refs.recommendProcess;
            this.candidateRecommendationHeader = this.$refs.candidateRecommendationHeader;
            // this.candidateArticle = this.$refs.candidateArticle;
            // this.candidateRecommendationReaderList = this.$refs.candidateRecommendationReaderList;
            this.candidateRecommendationTimeline = this.$refs.candidateRecommendationTimeline;
            this.candidateEmailShare = this.$refs.candidateEmailShare;
            this.candidateRecommendationSimilarResume = this.$refs.candidateRecommendationSimilarResume;
            // this.candidatePrint = this.$refs.candidatePrint;
            // this.emailShareBtn = this.$refs.emailShare;
            // this.printBtn = this.$refs.print;
            // this.uploadSpinner = this.$refs.uploadSpinner;
            this.initEvent();

        },
        methods: {
            // 判断是否可以评分
            checkCanEvaluate() {
                this.loading = true;
                _request({
                    url: jobUrl.check_can_evaluate.replace(/\%p/ig, this.myrecommendationId),
                    method: "GET",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                }).then((res) => {
                    this.loading = false;
                    this.canEvalute = res.success;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            // 是否进行过评分了
            isEvalute(value) {
                this.hasEvalute = value;
            },
            
            // handleUploadSuccess(response, file, fileList){
            //     if(response.succeeded){
            //         this.attachmentList.refresh();
            //     }else{
            //         this.$message.error(response.message);
            //     }
            //     this.uploadSpinner.sleep();
            // },
            // handleUploadError(err, file, fileList){
            //     this.$message.error('上传附件错误，请刷新页面重新尝试！');
            //     this.uploadSpinner.sleep();
            // },
            // handleBeforeUpload(file){
            //     const isLt2M = file.size / 1024 / 1024 < 2;
            //     this.uploadSpinner.wake();
            //     if (!isLt2M) {
            //         this.$message.error('上传附件大小不能超过 2MB!');
            //         this.uploadSpinner.sleep();
            //     }
            //     return isLt2M;
            // },
            // handlePrint(){
            //     this.candidateArticle.showContent();
            //     this.candidatePrint.print();
            //     // window.print();
            // },
            AtandTip(id){
                // this.comments.atAndTip(id);
            },
            handelReaderList(){
                // this.candidateRecommendationReaderList.refresh(this.recommendationId);
            },
            handelRecordRefresh(){
                this.$emit('candidate-search-record-refresh');
            },
            attachmentRefresh(){
                this.attachmentList.refresh(this.myrecommendationId);
            },
            timelineRefresh(){
                this.candidateRecommendationTimeline.refresh();
            },
            interviewSuccess() {
                if (this.isCloudRecommendation) {
                    this.$emit('cloud-candidate-refresh');
                }
            },
            handleEmailShare(){
                this.candidateEmailShare.show(this.recommendationId);
            },
            handelSimilarResume(){
                this.candidateRecommendationSimilarResume.show(this.recommendationId);
            },
            initEvent(){

                // this.subscribe(commentEvent.send.success, function(){
                //     this.comments.refresh();
                //     return false;
                // });

                // this.subscribe(recommendProcessEvent.state.change, function (data) {
                //     this.updateArticleEditPermission(data.status);
                //     this.candidateRecommendationTimeline.refresh();
                //     return false;
                // });

                // this.subscribe(emailShareConfig.success, function (vm) {
                //     const recommendationId = vm.recommendationId;
                //     this.candidateRecommendationReaderList.refresh(recommendationId);
                //     return false;
                // });

                // this.subscribe(emailShareConfig.send, function () {
                //     this.candidateEmailShare.show(this.recommendationId);
                //     return false;
                // });

                // this.subscribe(recommendationReadEvent.success, function(param){
                //     this.candidateRecommendationReaderList.refresh(this.recommendationId);
                //     return false;
                // });
            },
            handleProcessSuccess(summary){
                this.curJobType = summary.jobType;
                // this.updateArticleEditPermission(summary.status);
                // 简历的同步在刷新的时候进行
                // this.candidateArticle.refresh(this._lastArticle);
                this.isPublisher = summary.isPublisher;
                this.isRecommender = summary.isRecommender;

                this.publisherInfo = summary.publisherInfo;

                this.candidateRecommendationTimeline.refresh(summary.id);

                var callee = null;
                var interviewViewModel = summary.interviewViewModel;
                if (summary.isProjectManagerOfJob || summary.isPublisher) {
                    callee = summary.recommenderInfo;
                    callee.action = '推荐';
                } else {
                    callee = summary.publisherInfo;
                    callee.action = '发布';
                }
                this.candidateRecommendationHeader.callee = callee;
                this.candidateRecommendationHeader.canCall = summary.canCallRecommender;
                this.candidateRecommendationHeader.firmName = interviewViewModel.firmShortName;
                this.candidateRecommendationHeader.jobName = interviewViewModel.jobName;
                this.candidateRecommendationHeader.location = interviewViewModel.jobLocation;
                this.candidateRecommendationHeader.jobId = interviewViewModel.jobId;
                this.loading = false;
                // if(summary.sharedLink){
                //     this.qrcodePopoverTpl = placeholder(qrcodePopoverTpl, summary.sharedLink);
                // }
                return false;
            },
            updateArticle(vm){
                this._lastArticle = vm;
            },

            // updateArticleEditPermission(status){
            //     // 接受之后的简历不能更改
            //     this.candidateArticle.changePermission(status < ACCEPT_STATUS);
            //     if(status < ACCEPT_STATUS){
            //         this.mycanEdit=true;
            //     }else{
            //         this.mycanEdit=false;
            //     }
            // },
            // updateCandidaterecommendationheader(vm){
            //     this.candidateRecommendationHeader.update(vm)
            // },
            refresh(id){
                this.myrecommendationId = id;
                if(this.myrecommendationId){
                    this.loading = true;
                    
                    this.checkCanEvaluate();
                    // console.log(11111111111);
                    // console.log(this.$route);
                    // this.recommendDate = this.$route.query.recommendDate;
                    this.recommendDate = decodeURIComponent(location.href.split('?recommendDate=')[1]);

                    this.recommendProcess.refresh(this.myrecommendationId);
                    // this.candidateRecommendationReaderList.refresh(this.myrecommendationId);
                    // this.comments.defaults.postId = this.myrecommendationId;
                    // this.comments.refresh();
                    this.candidateEmailShare.refresh(this.myrecommendationId);
                    this.attachmentList.refresh(this.myrecommendationId);
                }
            },
            handleRouterNavigate(val){
                this.$emit('router-navigate',val);
            },
            showOfferDialog() {
                if(this.publisherInfo.firmId !== this.$store.state.user.userInfo.firmId) {
                    this.recommendProcess.$refs.offerDialog.showHandle(this.recommendationId,true, 'offer', true, true);
                } else {
                    this.recommendProcess.$refs.offerDialog.showHandle(this.recommendationId,true, 'offer', true);
                }
            },
            offerEditSuccess() {
                this.candidateRecommendationTimeline.loadcontent();
            },
            showRecommendAttachment(hasRecommendAttachment) {
                this.hasRecommendAttachment = hasRecommendAttachment;
            }
        }
    }

</script>


<style lang="scss">
    $red: #ff6666;
    .candidate-recommendation-detail{
        // width: 830px;
        // border: 1px solid #ddd;
        border-top: none;
        // padding: 20px 30px;
        // background-color: #fff;
        .attachment-lists{
            .order-item {
                float: left;
                display: inline-block;
                width: auto;
            }
        }
        .attachment-item {
            .item-detail {
                width: auto;
            }
            
            .secret{
                    color: $red;
                    cursor: pointer;
                    font-size: 12px;
                    border-radius: 3px;
                    border: 1px solid $red;
                    margin-left: 5px;
                    padding: 1px 3px;
            }
            .menu {
                float: none !important;
                vertical-align: text-top;
                line-height: 1em;
                > li {
                    border: none;
                    padding: 0 4px;
                    vertical-align: sub;
                    margin: 0;
                }

                .attachment-remove:before {
                    content: "X";
                    position: absolute;
                    width: 25px;
                    height: 20px;
                    z-index: 11;
                    background: #fff;
                    cursor: pointer;
                }

            }

        }

        .order{
            min-height: auto !important;
        }
        .pager {
            margin: 0;
        }
        .file-uploader{
            float: left;
        }
        .right-opr{
            float: right;
            .edit-link{
                color: #b9b9b9;
                font-size: 14px;
            }
        }
        .candidate-article{
            background: #fff;
            padding: 20px;
            margin: 20px 0;
            width: 830px;
            .title{
                margin: -20px;
                background: #f4f7f7;

                button{
                    background: #f4f7f7!important;
                }
            }
        }
        .comments-wrap{
            background: #fff;
            // width: 830px;
            .title{
                background: #f4f7f7;
            }
            form,.comments{
                padding: 20px;
            }
        }
    }
    .popover {
        .qr-code{
            opacity: 0.5;
        }
    }

    .email-share-margin{
        margin-top: 3px;
    }
    .recommendation-detail-content{
        .candidate-icon{
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
    }
    // .print-margin{
    //     margin-top: 3px;
    //     margin-left: 20px;
    // }
    // .candidate-recommendation-detail .right-opr {
    //     position: relative;
    // }
    // .candidate-recommendation-detail .resume-upload{
    //     display: inline-block;
    //     float:right;
    //     .el-upload__input{
    //         display: none;
    //     }
    //     .icon-upload{
    //         font-size: 14px;
    //         color: #B9B9B9;
    //         span{
    //             padding-left: 5px;
    //         }
    //     }
    // }
</style>
<style lang="scss" scope>
.candidate-recommendation-detail {
    .top-wrap {
        padding: 10px 15px 10px;
    }
    .recommend-attachment {
        // display: flex;
        margin-bottom: 15px;
        height: 28px;
        .recommend-attachment-title {
            padding-top: 3px;
        }
    }

    .recommendation-detail-content {
        .blank-block{
            padding: 10px 15px 0 0;
            margin-bottom: 22px;
            .tit-cell{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 14px;
                .tit{
                    font-size: 16px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 22px;
                    margin-right: 13px;
                    &:before{
                        display: inline-block;
                        content: '';
                        width: 3px;
                        height: 16px;
                        background: #38BC9D;
                        margin-right: 14px;
                        vertical-align: -2px;
                    }
                }
                .line{
                    // width: 689px;
                    height: 2px;
                    border: 1px solid rgba(235,235,235,0.92);
                    flex: 1;
                }
                .expand{
                    font-size: 14px;
                    font-weight: 500;
                    color: #38BC9D;
                    line-height: 20px;
                    margin-left: 13px;
                    cursor: pointer;
                    .expand-icon{
                        margin-left: 6px;
                    }
                }
            }
            .content{
                padding-left: 16px;
            }
        }
    }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "candidate-recommendation-detail" },
        [
          _c(
            "div",
            { staticClass: "top-wrap" },
            [
              _c("candidate-recommendation-header", {
                ref: "candidateRecommendationHeader",
                staticClass: "card",
                attrs: {
                  "resume-id": _vm.resumeId,
                  shareData: _vm.shareData,
                  currentJobType: _vm.curJobType,
                  recommendDate: _vm.recommendDate,
                  "is-cloud-recommendation": _vm.isCloudRecommendation,
                },
                on: {
                  "candidate-search-record-refresh": _vm.handelRecordRefresh,
                  "router-navigate": _vm.handleRouterNavigate,
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasRecommendAttachment,
                      expression: "hasRecommendAttachment",
                    },
                  ],
                  staticClass: "recommend-attachment",
                },
                [
                  _c("attachment-list", {
                    ref: "attachmentList",
                    attrs: {
                      recommendationId: _vm.recommendationId,
                      candidateName: _vm.shareData
                        ? _vm.shareData.candidateName
                        : "",
                      torecommends: false,
                      type: "recommend",
                    },
                    on: { "has-attachment": _vm.showRecommendAttachment },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "recommendation-detail-content" }, [
            _c("div", { staticClass: "blank-block" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("recommend-process", {
                    ref: "recommendProcess",
                    staticClass: "card",
                    attrs: {
                      recommendationId: _vm.recommendationId,
                      isPmOrHrPage: [3, 5, 4].includes(_vm.curJobType),
                    },
                    on: {
                      "recommend-process-success": _vm.handleProcessSuccess,
                      timelineRefresh: _vm.timelineRefresh,
                      "comment-at-and-tip": _vm.AtandTip,
                      "similar-resume": _vm.handelSimilarResume,
                      transfer: _vm.handleEmailShare,
                      successInterview: _vm.interviewSuccess,
                      successEditOffer: _vm.offerEditSuccess,
                      attachmentRefresh: _vm.attachmentRefresh,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "blank-block", attrs: { id: "timeline" } },
              [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("candidate-recommendation-timeline", {
                      ref: "candidateRecommendationTimeline",
                      staticClass: "card",
                      attrs: {
                        recommendationId: _vm.recommendationId,
                        isPublisher: _vm.isPublisher,
                      },
                      on: { "show-offer-dialog": _vm.showOfferDialog },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("candidate-recommendation-similar-resume", {
            ref: "candidateRecommendationSimilarResume",
          }),
          _c("candidate-email-share", {
            ref: "candidateEmailShare",
            attrs: { recommendationId: _vm.recommendationId },
            on: { "emailshare-success": _vm.handelReaderList },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit-cell" }, [
      _c("span", { staticClass: "tit" }, [_vm._v("当前状态")]),
      _c("span", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit-cell" }, [
      _c("span", { staticClass: "tit" }, [_vm._v("时间线")]),
      _c("span", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "candidate-recommendation-record" },
    [
      _vm.isAgreementJob
        ? _c("el-alert", {
            attrs: {
              title: `当前显示 ${_vm.recommendationCount} 条`,
              closable: false,
              type: "error",
            },
          })
        : _vm._e(),
      _vm.list.length == 0 && !_vm.loading ? _c("empty") : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.list.length > 0,
                  expression: "list.length > 0",
                },
              ],
              staticClass: "recommendation-list",
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                { key: index + item.id, staticClass: "recommend-hd" },
                [
                  _c("div", { staticClass: "info-and-tag-container" }, [
                    _c("div", { staticClass: "info-container" }, [
                      _c("div", { staticClass: "job-title" }, [
                        item.canAccess
                          ? _c(
                              "a",
                              {
                                staticClass: "job-name-color",
                                attrs: {
                                  href: item.href || "javascript: void(0);",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRouterNavigate(item)
                                  },
                                },
                              },
                              [
                                _c("h4", { staticClass: "recommend-job" }, [
                                  _c("span", { staticClass: "company" }, [
                                    _vm._v(_vm._s(item.firmName) + "-"),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(item.jobName))]),
                                  _c("span", { staticClass: "city" }, [
                                    _vm._v("-" + _vm._s(item.location)),
                                  ]),
                                ]),
                              ]
                            )
                          : _c("h4", { staticClass: "recommend-job" }, [
                              _c("span", { staticClass: "company" }, [
                                _vm._v(_vm._s(item.firmName) + "-"),
                              ]),
                              _c("span", [_vm._v(_vm._s(item.jobName))]),
                              _c("span", { staticClass: "city" }, [
                                _vm._v("-" + _vm._s(item.location)),
                              ]),
                            ]),
                      ]),
                      _c("div", { staticClass: "job-info" }, [
                        _c(
                          "span",
                          { staticClass: "salary" },
                          [
                            item.salary == "0K-0K"
                              ? [
                                  _vm._v(
                                    "\n                                    面议\n                                "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm._f("tpl")(item.salary, "%p")) +
                                      "\n                                "
                                  ),
                                ],
                          ],
                          2
                        ),
                        _c(
                          "span",
                          { staticClass: "commission" },
                          [
                            item.salary == "0K-0K"
                              ? [
                                  _vm._v(
                                    "\n                                    佣金 按比例" +
                                      _vm._s(
                                        _vm.filterCommisionValue(
                                          item.commisionValue
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                                    佣金 " +
                                      _vm._s(item.commisionValue) +
                                      "\n                                "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "job-user" },
                        [
                          _c("avatar", {
                            attrs: {
                              src: _vm._f("avatar")(item.headhunterAvatarUrl),
                              userId: item.headhunterId,
                              alt: item.headhunterRealName,
                              size: "sm",
                              shareData: _vm.shareData,
                            },
                          }),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("tpl")(
                                  _vm._f("datetime")(item.recommendDate),
                                  "%p 推荐"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "tag-container",
                        on: {
                          click: function ($event) {
                            return _vm.handleRouterNavigate(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "job-panel" }, [
                          _c(
                            "div",
                            { staticClass: "status" },
                            [
                              _c("recommendation-status-tag", {
                                attrs: {
                                  interviewConfirmedStatus:
                                    item.interviewConfirmedStatus,
                                  href: item.recommendationUrl,
                                  label: item.recommendStatus,
                                  flowStepName: item.flowStepName,
                                },
                              }),
                              _c("i", { staticClass: "el-icon-arrow-right" }),
                            ],
                            1
                          ),
                          item.commentsCount
                            ? _c("span", { staticClass: "comment-count" }, [
                                _c("i", { staticClass: "fa fa-comment" }),
                                _c("span", [
                                  _vm._v(_vm._s(item.commentsCount)),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm.recommendationCount > 10 && _vm.list.length > 0
        ? _c(
            "el-pagination",
            {
              staticClass: "recommendation-record-pagination",
              attrs: {
                "current-page": _vm.start,
                "page-sizes": [10, 30, 50],
                "page-size": _vm.take,
                layout: "total, sizes, prev, pager, next, slot",
                total: _vm.recommendationCount,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("\n                前往\n                "),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump(_vm.pagerJump)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("\n                页\n            "),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "candidate-recommendation-header recommendation-header-vue",
      staticStyle: { display: "flex" },
    },
    [
      _c("div", { staticClass: "user-infos" }, [
        _c("div", { staticClass: "info-row" }, [
          _c("div", { staticClass: "ir-l" }, [
            _c("span", { staticClass: "recommend-angle" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.jobId
                      ? "/#/Job/" + _vm.jobId
                      : "javascript: void(0)",
                    target: "_blank",
                  },
                },
                [
                  _c("span", {
                    staticClass: "title-font-color",
                    domProps: { textContent: _vm._s(_vm.getJobLabel()) },
                  }),
                ]
              ),
            ]),
            _c(
              "span",
              { staticClass: "pic" },
              [
                _c("avatar", {
                  attrs: {
                    userId: _vm.callee.id
                      ? _vm.callee.id
                      : _vm.defaultCreatorId,
                    src: _vm.callee.avatarUrl
                      ? _vm.callee.avatarUrl
                      : _vm.formatAvatarUrl(
                          `/Data/Avatar/${_vm.callee.avatarId}`
                        ),
                    enableLink: true,
                    enableName: true,
                    isRecommendation: true,
                    shareData: _vm.shareData,
                    size: "sm",
                  },
                }),
              ],
              1
            ),
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.callee.name) + "\n                    "),
              _c("span", { staticClass: "nick" }, [
                _vm._v("@" + _vm._s(_vm.callee.nickname)),
              ]),
            ]),
          ]),
          !_vm.isCloudRecommendation
            ? _c("div", { staticClass: "ir-r" }, [
                _c(
                  "span",
                  {
                    staticClass: "back",
                    on: { click: _vm.handleRouterNavigate },
                  },
                  [
                    _c("font-icon", { attrs: { href: "#icon-chexiao" } }),
                    _vm._v(
                      "\n                    返回全部推荐\n                "
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }